import {Alpine} from '@/alpineJS'
import {listingCardSlideGallery} from "@/modules/gallery";
import {featureSellers, swiperLocation, swiperHeroHome} from "@/modules/swiper/swiperInit";
import searchFilter from "@/pages/home/searchFilter";
import "@/modules/meilisearch/features/meilisearch.events";
import {initFormAlertModal} from "@/modules/modals/modal";
import {meilisearchInit} from "@/modules/meilisearch/meilisearch-init";

// Store data for search filter
Alpine.data('searchFilter', searchFilter)

// init meilisearch
meilisearchInit()

// Card Slide Gallery
listingCardSlideGallery();
// Hero Swiper
swiperHeroHome();
// Popular Searches Swiper
swiperLocation();
// Feature Sellers Swiper
featureSellers();
// init form alert modal
initFormAlertModal();

/**
 * Submit search when click on search-button
 * */
/*eventListener(document.getElementById(SUBMIT_SEARCH_BUTTON), 'click', (e) => {
  let firstElement = document.querySelector('.dropdown-selected') as HTMLElement;
  if (firstElement) {
    firstElement.click();
  } else {
    let tenure =  (INPUT_IDS.TENURE);
    let language = getInputValue(INPUT_IDS.LANG)
    window.location.href = `/${language}/${tenure}`
  }
});*/

/**
 * Key press Enter when empty-value to submit search
 * */
/*eventListener(document.getElementById(INPUT_IDS.SEARCH_BOX), 'keyup', (e) => {
  const searchValue = getInputValue(INPUT_IDS.SEARCH_BOX)
  if (!searchValue.length && e.key === 'Enter') {
    let tenure = getInputValue(INPUT_IDS.TENURE);
    let language = getInputValue(INPUT_IDS.LANG)
    window.location.href = `/${language}/${tenure}`
  }
});*/
window.Alpine = Alpine
Alpine.start()
